import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";
import Subscribe from "../components/subscribe/Subscribe";

function SubscribePage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps"]}
          location={location}
          title="S'inscrire"
        />
        <Page noHeader>
          <Subscribe />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

SubscribePage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default SubscribePage;

import PropTypes from "prop-types";
import React, { Component } from "react";

import validIcon from "../../../images/Icon/Support/Check.svg";
import errorIcon from "../../../images/Icon/Support/Error.svg";
import styles from "./Textarea.module.scss";

const strings = {
  mandatory: "Obligatoire",
};

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pristine: true,
      error: false,
      valid: void 0,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  handleOnBlur = (e) => {
    const { mandatory } = this.props;
    const value = e.target.value;
    this.setState({ pristine: false });
    if (!value && mandatory) {
      this.setState({ error: strings["mandatory"], valid: false });
    } else if (value) {
      this.check(value);
    }
  };

  handleOnChange = (e) => {
    const { mandatory } = this.props;
    const { pristine } = this.state;
    const value = e.target.value;
    if (!value && mandatory) {
      this.setState({ error: strings["mandatory"], valid: false });
    } else if (value) {
      if (!pristine) {
        this.check(value);
      }
    }
  };

  check = (value) => {
    const { valid } = this.props;
    const error = valid(value);
    if (error) {
      this.setState({ error, valid: false });
    } else {
      this.setState({ error: void 0, valid: true });
    }
  };

  render() {
    const { title, link, mandatory, placeholder, name, maxlength } = this.props;
    const { error, valid } = this.state;
    return (
      <div className={styles["container"]}>
        <div className={styles["title_container"]}>
          <div className={styles["title"]}>
            {title}
            {mandatory && <span className={styles["mandatory"]}>*</span>}
          </div>
          {link && <div>{link}</div>}
          {error && <div className={styles["error"]}>{error}</div>}
        </div>
        <div className={styles["input_container"]}>
          <textarea
            maxLength={maxlength}
            name={name}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            placeholder={placeholder}
            rows="5"
          />
          <div className={styles["icon"]}>
            {error && <img alt="error" src={errorIcon} />}
            {valid && <img alt="valid" src={validIcon} />}
          </div>
        </div>
      </div>
    );
  }
}

Textarea.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  mandatory: PropTypes.bool,
  maxlength: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.func,
};

Textarea.defaultProps = {
  link: void 0,
  mandatory: false,
  maxlength: void 0,
  name: "",
  placeholder: "",
  valid: void 0,
};

export default Textarea;

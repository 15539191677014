import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Form.module.scss";
import Input from "./input/Input";
import Textarea from "./textarea/Textarea";

const STATUS = {
  READY: "READY",
  SENDING: "SENDING",
  SENT: "SENT",
  ERROR: "ERROR",
};

function Form({
  children,
  title,
  btnLabel,
  footer,
  action,
  onSubmit,
  status,
  name,
}) {
  return (
    <div className={styles["container"]}>
      <form action={action} id={name} method="post" onSubmit={onSubmit}>
        {title && <div className={styles["title"]}>{title}</div>}
        {children}
        <div className={styles["title"]}>
          <div className={styles["btn"]}>
            <button
              className={classNames(styles["submit"], {
                [styles["sending"]]: status === STATUS.SENDING,
                [styles["sent"]]: status === STATUS.SENT,
                [styles["error"]]: status === STATUS.ERROR,
              })}
              type="submit"
            >
              <span className={styles["label"]}>{btnLabel}</span>
            </button>
          </div>
        </div>
        <div className={styles["footer"]}>{footer}</div>
      </form>
    </div>
  );
}

Form.STATUS = STATUS;

Form.Input = Input;
Form.Textarea = Textarea;

Form.propTypes = {
  action: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.node,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  status: PropTypes.oneOf(Object.values(STATUS)),
};

Form.defaultProps = {
  footer: void 0,
  name: void 0,
  onSubmit: void 0,
  status: STATUS.READY,
};

export default Form;
